// returns a formatted phone value for UI purposes

export default {
  methods: {
    formatPhone(phoneString) {
      if (phoneString) {
        let phone = phoneString;
        let cleaned = ("" + phone).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          phone = "(" + match[1] + ") " + match[2] + "-" + match[3];
          return phone;
        }
      }
    }
  }
}